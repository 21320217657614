import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledRemoveSlotContainer = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledRemoveSlotButton = styled(Button)`
  && {
    margin: 10px 0;
    min-width: 8.96rem;
    background-color: #ff1744;
    color: white;

    &:hover {
      background-color: #d50000;
    }

    &:disabled {
      background-color: #ef9a9a;
      color: white;
    }
  }
`;

export const StyledAddSlotContainer = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }
`;

export const StyledAddSlotButton = styled(Button)`
  && {
    margin: 10px 0;
    background-color: #4caf50;
    color: white;

    &:hover {
      background-color: #388e3c;
    }

    &:disabled {
      background-color: #a5d6a7;
      color: white;
    }
  }
`;

export const StyledWrapper = styled.div`
  && {
    margin-top: 15px;
  }
`;
