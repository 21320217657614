import { useState } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import TextField from "../../../../components/mui-components/TextField";
import { Controller, useFormContext } from "react-hook-form";

const getDefaultEndTime = (day) => {
  const weekendDays = ["FRIDAY", "SATURDAY", "SUNDAY"];
  return weekendDays.includes(day) ? "02:00" : "00:00";
};

const validateEndTime = (value, setError) => {
  if (!value || value.trim() === "") {
    setError("End time cannot be empty");
    return false;
  }

  setError("");
  return true;
};

const DeliveryAreaSlotsEndTime = ({
  id,
  endTime,
  control,
  hasDeliveryAreaDeliveryFeePermission,
  isDisabled,
}) => {
  const [customError, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { watch } = useFormContext();
  const selectedDay = watch(`deliveryAreaSlots[${id}].day`);

  const defaultEndTime = endTime || getDefaultEndTime(selectedDay);

  return (
    <>
      {!hasDeliveryAreaDeliveryFeePermission && (
        <FormControl
          variant="standard"
          fullWidth
          error={!!customError}
          onFocus={() => setShowError(true)}
          onBlur={() => setShowError(false)}
        >
          <Controller
            name={`deliveryAreaSlots[${id}].endTime`}
            control={control}
            defaultValue={defaultEndTime}
            rules={{
              required: "End time is required",
              validate: (value) =>
                validateEndTime(value, setError) || customError,
            }}
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error || !!customError;
              return (
                <>
                  <TextField
                    {...field}
                    name={`deliveryAreaSlots[${id}].endTime`}
                    type="time"
                    label="End Time"
                    disabled={isDisabled}
                    variant="standard"
                    error={hasError}
                    inputProps={{
                      style: {
                        color:
                          hasError && !isFocused ? "transparent" : "inherit",
                      },
                      step: 300,
                    }}
                    onChange={(event) => {
                      const value = event.target.value;
                      field.onChange(value);
                      validateEndTime(value, setError);
                    }}
                    onFocus={() => {
                      setIsFocused(true);
                      setShowError(true);
                    }}
                    onBlur={(event) => {
                      setIsFocused(false);
                      setShowError(false);
                      field.onBlur();
                      const value = event.target.value;
                      validateEndTime(value, setError);
                    }}
                  />

                  {showError && (
                    <FormHelperText error>
                      {customError || error?.message || ""}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </FormControl>
      )}
    </>
  );
};

export default DeliveryAreaSlotsEndTime;
