import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";
import TextField from "../../../../components/mui-components/TextField";

const validateQuota = (value, setError) => {
  if (!value) {
    setError("Quota cannot be empty");
    return false;
  }
  if (value < 0) {
    setError("Quota cannot be negative");
    return false;
  }
  setError("");
  return true;
};

const DeliveryAreaQuotas = ({
  id,
  quotas,
  defaultCapacity = 5,
  isDisabled,
}) => {
  const [customError, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const quotaValue = watch(`deliveryAreaSlots[${id}].quotas`);

  useEffect(() => {
    if (quotaValue !== undefined) {
      validateQuota(quotaValue, setError);
    }
  }, [quotaValue]);

  const fieldName = `deliveryAreaSlots[${id}].quotas`;
  const formError = errors?.deliveryAreaSlots?.[id]?.quotas;

  return (
    <FormControl
      variant="standard"
      fullWidth
      error={!!formError || !!customError}
      onFocus={() => setShowError(true)}
      onBlur={() => setShowError(false)}
    >
      <Controller
        name={fieldName}
        control={control}
        defaultValue={quotas || defaultCapacity}
        rules={{
          required: "Quota is required",
          validate: (value) => validateQuota(value, setError) || customError,
        }}
        render={({ field }) => (
          <TextField
            {...field}
            name={fieldName}
            type="number"
            label="Quotas"
            disabled={isDisabled}
            variant="standard"
            inputProps={{ min: 0 }}
            error={!!formError || !!customError}
            onChange={(event) => {
              const value = event.target.value;
              const parsedValue = value ? parseInt(value, 10) : "";
              field.onChange(parsedValue);
              validateQuota(parsedValue, setError);
            }}
          />
        )}
      />

      {showError && (
        <FormHelperText error>
          {customError || formError?.message || ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default DeliveryAreaQuotas;
