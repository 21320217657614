import { useState } from "react";
import { Button, Typography, Grid } from "@mui/material";
import { useForm } from "react-hook-form";

import {
  StyledHubFormPageContainer,
  StyledHubFormGridContainer,
  StyledHubFormTitleGridContainer,
} from "../styles";

import { usePermissionContext } from "../../context/PermissionContext";
import { useNotificationContext } from "../../context/NotificationContext";
import { useGetDeliveryAreaByIdQuery } from "../../sources/queries";
import {
  useEditDeliveryAreaMutation,
  useDeleteDeliveryAreaMutation,
  useEditDeliveryAreaDeliveryFeeMutation,
  useEditDeliveryAreaSlotsMutation,
  useDeleteDeliveryAreaSlotsMutation,
} from "../../sources/mutations";

import { FormSkeleton } from "../../components/placeholders/FormSkeleton";
import { ConfirmationDialog } from "../../components/modals/ConfirmationDialog";

import { DeliveryAreaForm } from "../../components/form/DeliveryAreaForm";
import { delivery_area_schema } from "../../components/form/validation";
import { addTypeToProperties } from "../../components/form/propertyTypes";
import { useNavigate } from "react-router-dom";

export const DeliveryAreaEdit = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      deliveryAreaSlots: [],
    },
    resolver: delivery_area_schema,
    mode: "onChange",
  });
  const { hasReadOnlyPermission, hasDeliveryAreaDeliveryFeePermission } =
    usePermissionContext();
  const { notify } = useNotificationContext();

  const { data, isLoading: loadingDeliveryArea } = useGetDeliveryAreaByIdQuery({
    onSuccess: (result) => {
      methods.reset(addTypeToProperties(result?.data));
    },
    keepPreviousData: false,
    cacheTime: 0,
  });

  const { mutate: updateSlots, isLoading: loadingUpdateSlots } =
    useEditDeliveryAreaSlotsMutation({
      onSuccess: () => {
        notify({
          severity: "success",
          message: "Delivery area slots updated successfully!",
        });
        navigate("/delivery-area");
      },
      onError: () => {
        notify({
          severity: "error",
          message: "Failed to update delivery area slots.",
        });
      },
    });

  const { mutate: deleteDeliveryAreaSlots, isLoading: loadingDeleteSlots } =
    useDeleteDeliveryAreaSlotsMutation({
      onSuccess: () => {
        notify({
          severity: "success",
          message: "Delivery area slots deleted successfully!",
        });
        navigate("/delivery-area");
      },
      onError: (message) => {
        notify({
          severity: "error",
          message: message || "Failed to delete delivery area slots.",
        });
      },
    });

  const { mutate: updateDeliveryArea, isLoading: loadingUpdateDeliveryArea } =
    useEditDeliveryAreaMutation({
      onSuccess: ({ data, success, message }) => {
        if (success) {
          methods.reset(data);
          navigate("/delivery-area");
          notify({
            severity: "success",
            message: "Delivery area updated successfully !",
          });
        } else {
          notify({ severity: "error", message });
        }
      },
      onError: () => {
        notify({ severity: "error", message: "Something went wrong." });
      },
    });

  const {
    mutate: updateDeliveryFeeArea,
    isLoading: loadingUpdateDeliveryFeeArea,
  } = useEditDeliveryAreaDeliveryFeeMutation({
    onSuccess: ({ data, success, message }) => {
      if (success) {
        methods.reset(data);
        navigate("/delivery-area");
        notify({
          severity: "success",
          message: "Delivery area updated successfully !",
        });
      } else {
        notify({ severity: "error", message });
      }
    },
    onError: () => {
      notify({ severity: "error", message: "Something went wrong." });
    },
  });

  const { mutate: deleteDeliveryArea, isLoading: loadingDeleteDeliveryArea } =
    useDeleteDeliveryAreaMutation();

  if (loadingDeliveryArea || !data?.data) return <FormSkeleton />;

  const onCancel = () => {
    setOpen(false);
  };
  const onAction = () => {
    deleteDeliveryAreaSlots();
    deleteDeliveryArea();
  };

  return (
    <StyledHubFormPageContainer maxWidth="md">
      <StyledHubFormTitleGridContainer container>
        <Grid item xs={12}>
          <Typography variant="h4">
            {hasReadOnlyPermission
              ? "Delivery Area Info"
              : "Edit Delivery Area"}
          </Typography>
        </Grid>
      </StyledHubFormTitleGridContainer>
      <DeliveryAreaForm
        methods={methods}
        disabled={hasReadOnlyPermission}
        isEditForm
        renderFormActions={() => {
          return (
            !hasReadOnlyPermission && (
              <StyledHubFormGridContainer container spacing={4}>
                <Grid item xs={8}>
                  <Button
                    fullWidth
                    variant="contained"
                    disableRipple
                    color="success"
                    onClick={methods.handleSubmit((fields) => {
                      const { deliveryAreaSlots, ...filteredFields } = fields;

                      if (!hasDeliveryAreaDeliveryFeePermission) {
                        updateDeliveryArea({
                          ...filteredFields,
                          etaReason: { id: fields.etaReason.id },
                          etaValue: { id: fields.etaValue.id },
                        });
                      } else {
                        updateDeliveryFeeArea({
                          id: fields.id,
                          deliveryFeeAreaConfiguration:
                            fields.deliveryFeeAreaConfiguration,
                        });
                      }

                      const deliveryMode = fields.deliveryAreaConfiguration.deliveryMode;

                      if (deliveryAreaSlots?.length !== 0 && deliveryMode === "INSTANT_OR_SCHEDULED"
                        || deliveryAreaSlots?.length !== 0 && deliveryMode === "SCHEDULED_ONLY") {
                        const filteredSlots = deliveryAreaSlots?.filter(
                          (slot) =>
                            slot &&
                            Object.values(slot).some(
                              (value) => value !== undefined,
                            ),
                        );

                        const updatedSlots = filteredSlots?.map((slot) => ({
                          dayOfWeek: slot.day?.toUpperCase(),
                          slotFrom: slot.startTime,
                          slotTo: slot.endTime,
                          maxConcurrent: parseInt(slot.quotas, 10),
                          enabled: slot.status,
                        }));

                        updateSlots(updatedSlots);
                      }
                    })}
                    disabled={
                      loadingUpdateDeliveryArea ||
                      loadingDeleteDeliveryArea ||
                      loadingUpdateDeliveryFeeArea ||
                      loadingUpdateSlots ||
                      loadingUpdateSlots
                    }
                  >
                    Save Changes
                  </Button>
                </Grid>
                {!hasDeliveryAreaDeliveryFeePermission && (
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      disableRipple
                      color="error"
                      onClick={() => {
                        setOpen(true);
                      }}
                      disabled={
                        loadingUpdateDeliveryArea ||
                        loadingDeleteDeliveryArea ||
                        loadingUpdateSlots ||
                        loadingDeleteSlots
                      }
                    >
                      Delete Delivery Area
                    </Button>
                  </Grid>
                )}
              </StyledHubFormGridContainer>
            )
          );
        }}
      />

      <ConfirmationDialog
        open={open}
        onCancel={onCancel}
        onAction={onAction}
        title={"Are you sure?"}
        message={`This action cannot be undone. This will permanently delete ${data?.data?.hubId} delivery area.`}
        property={true}
      />
    </StyledHubFormPageContainer>
  );
};
