import { Controller } from "react-hook-form";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "../../../../components/mui-components/Select";

const days = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

const DeliveryAreaSlotsDay = ({
  dayOfWeek,
  id,
  control,
  hasDeliveryAreaDeliveryFeePermission,
  isDisabled,
}) => {
  const fieldName = `deliveryAreaSlots[${id}].day`;
  return (
    <>
      {!hasDeliveryAreaDeliveryFeePermission && (
        <FormControl variant="standard" fullWidth>
          <InputLabel id="delivery-day-select-label">Day</InputLabel>
          <Controller
            name={fieldName}
            control={control}
            defaultValue={dayOfWeek || days[0]}
            render={({ field }) => (
              <Select
                {...field}
                name={fieldName}
                labelId="delivery-day-select-label"
                disabled={isDisabled}
                label="Day"
              >
                {days.map((day, index) => (
                  <MenuItem key={index} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      )}
    </>
  );
};

export default DeliveryAreaSlotsDay;
