import { Controller } from "react-hook-form";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select from "../../../../components/mui-components/Select";

const status = [
  { label: "Enable", value: true },
  { label: "Disable", value: false },
];

const getStatusLabel = (statusValue) => {
  const statusObject = status.find((item) => item.value === statusValue);
  return statusObject ? statusObject.value : "Unknown";
};

const DeliveryAreaSlotsStatus = ({
  id,
  status: defaultStatus,
  control,
  hasDeliveryAreaDeliveryFeePermission,
  isDisabled,
}) => {
  const statusLabel = getStatusLabel(defaultStatus);

  return (
    <>
      {!hasDeliveryAreaDeliveryFeePermission && (
        <FormControl variant="standard" fullWidth>
          <InputLabel id="delivery-status-select-label">Status</InputLabel>
          <Controller
            name={`deliveryAreaSlots[${id}].status`}
            control={control}
            defaultValue={statusLabel}
            render={({ field }) => (
              <Select
                {...field}
                labelId="delivery-status-select-label"
                disabled={isDisabled}
                label="Status"
              >
                {status.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      )}
    </>
  );
};

export default DeliveryAreaSlotsStatus;
