import { useState } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import TextField from "../../../../components/mui-components/TextField";
import { Controller } from "react-hook-form";

const validateStartTime = (value, setError) => {
  if (!value || value.trim() === "") {
    setError("Start time cannot be empty");
    return false;
  }

  setError("");
  return true;
};

const DeliveryAreaSlotsStartTime = ({
  startTime,
  id,
  control,
  hasDeliveryAreaDeliveryFeePermission,
  isDisabled,
}) => {
  const [customError, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const fieldName = `deliveryAreaSlots[${id}].startTime`;

  return (
    <>
      {!hasDeliveryAreaDeliveryFeePermission && (
        <FormControl
          variant="standard"
          fullWidth
          error={!!customError}
          onFocus={() => setShowError(true)}
          onBlur={() => setShowError(false)}
        >
          <Controller
            name={fieldName}
            control={control}
            defaultValue={startTime || "08:00"}
            rules={{
              required: "Start time is required",
              validate: (value) =>
                validateStartTime(value, setError) || customError,
            }}
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error || !!customError;
              return (
                <>
                  <TextField
                    {...field}
                    name={fieldName}
                    type="time"
                    label="Start Time"
                    disabled={isDisabled}
                    variant="standard"
                    error={hasError}
                    inputProps={{
                      style: {
                        color:
                          hasError && !isFocused ? "transparent" : "inherit",
                      },
                      step: 300,
                    }}
                    onChange={(event) => {
                      const value = event.target.value;
                      field.onChange(value);
                      validateStartTime(value, setError);
                    }}
                    onFocus={() => {
                      setIsFocused(true);
                      setShowError(true);
                    }}
                    onBlur={(event) => {
                      setIsFocused(false);
                      setShowError(false);
                      field.onBlur();
                      const value = event.target.value;
                      validateStartTime(value, setError);
                    }}
                  />

                  {showError && (
                    <FormHelperText error>
                      {customError || error?.message || ""}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        </FormControl>
      )}
    </>
  );
};

export default DeliveryAreaSlotsStartTime;
