import { useState, useCallback } from "react";
import DeliveryAreaSlotsDay from "./DeliveryAreaSlotsDay";
import DeliveryAreaSlotsEndTime from "./DeliveryAreaSlotsEndTime";
import DeliveryAreaSlotsStartTime from "./DeliveryAreaSlotsStartTime";
import DeliveryAreaSlotsQuotas from "./DeliveryAreaSlotsQuotas";
import DeliveryAreaSlotsStatus from "./DeliveryAreaSlotsStatus";
import { useGetDeliveryAreaSlotsQuery } from "../../../../sources/queries";
import { useDeleteDeliveryAreaSlotsByIdsMutation } from "../../../../sources/mutations";
import { useNotificationContext } from "../../../../context/NotificationContext";
import {
  StyledAddSlotButton,
  StyledAddSlotContainer,
  StyledRemoveSlotButton,
  StyledRemoveSlotContainer,
  StyledWrapper,
} from "./styles";
import { useFormContext } from "react-hook-form";
import { addTypeToProperties } from "../../propertyTypes";

const DELIVERY_MODE = "deliveryAreaConfiguration.deliveryMode";

const getNextSlotId = (slotsData, localSlots) => {
  const existingIds = [
    ...(slotsData?.data?.map((slot) => Number(slot.id)) || []),
    ...localSlots.map((slot) => Number(slot.id)),
  ].filter((id) => !isNaN(id));

  return existingIds.length > 0 ? Math.max(...existingIds) + 1 : 1;
};

const DeliveryAreaSlots = ({
  isDisabled: globalIsDisabled,
  isEditForm,
  hasDeliveryAreaDeliveryFeePermission,
}) => {
  const [removingSlotId, setRemovingSlotId] = useState(null);
  const [localSlots, setLocalSlots] = useState([]);
  const { notify } = useNotificationContext();
  const { control, reset, watch, getValues } = useFormContext();

  const deliveryMode = watch(DELIVERY_MODE);

  const { data: slotsData, refetch: refetchSlots } =
    useGetDeliveryAreaSlotsQuery({
      onSuccess: (result) => {
        reset(addTypeToProperties(result?.data));
      },
      keepPreviousData: false,
      cacheTime: 0,
    });

  const { mutate: deleteSlotsByIds, isLoading: loadingDeleteSlotsByIds } =
    useDeleteDeliveryAreaSlotsByIdsMutation({
      onSuccess: () => {
        notify({ severity: "success", message: "Slot deleted successfully!" });
        setRemovingSlotId(null);
        refetchSlots();
      },
      onError: (error) => {
        console.error("Error deleting slot:", error);
        notify({
          severity: "error",
          message: "Failed to delete the slot. Please try again.",
        });
        setRemovingSlotId(null);
      },
    });

  const handleAddNewSlot = () => {
    const newId = getNextSlotId(slotsData, localSlots);

    const combinedSlots = [...sortedSlotsData, ...localSlots];

    const lastDayOfWeek =
      combinedSlots.length > 0
        ? combinedSlots[combinedSlots.length - 1].dayOfWeek
        : null;

    const lastIndex = lastDayOfWeek
      ? dayOfWeekOrder.indexOf(lastDayOfWeek)
      : -1;

    const nextDayOfWeek =
      lastIndex >= 0 && lastIndex < dayOfWeekOrder.length - 1
        ? dayOfWeekOrder[lastIndex + 1]
        : dayOfWeekOrder[0];

    const isWeekend =
      nextDayOfWeek === "FRIDAY" ||
      nextDayOfWeek === "SATURDAY" ||
      nextDayOfWeek === "SUNDAY";

    const newSlot = {
      id: newId,
      dayOfWeek: nextDayOfWeek,
      slotFrom: "08:00",
      slotTo: isWeekend ? "02:00" : "00:00",
      maxConcurrent: 5,
      enabled: true,
    };

    setLocalSlots((prev) => [...prev, newSlot]);
  };

  const handleDeleteSlot = useCallback(
    (slotId) => {
      setRemovingSlotId(slotId);

      if (!slotsData?.data?.some((slot) => slot.id === slotId)) {
        setLocalSlots((prev) => prev.filter((slot) => slot.id !== slotId));
        setRemovingSlotId(null);
      } else {
        deleteSlotsByIds([slotId]);
      }
    },
    [deleteSlotsByIds, slotsData?.data]
  );

  const dayOfWeekOrder = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  const sortedSlotsData = (slotsData?.data || []).sort(
    (a, b) =>
      dayOfWeekOrder.indexOf(a.dayOfWeek) - dayOfWeekOrder.indexOf(b.dayOfWeek)
  );

  const combinedSlots = [...sortedSlotsData, ...localSlots];

  return (
    ((deliveryMode === "INSTANT_OR_SCHEDULED" && isEditForm) ||
      (deliveryMode === "SCHEDULED_ONLY" && isEditForm)) && (
    <StyledWrapper>
      {combinedSlots.map((slot, index) => {
        return (
          <div key={slot.id || index}>
            <DeliveryAreaSlotsDay
              id={slot.id}
              control={control}
              isDisabled={globalIsDisabled}
              dayOfWeek={slot.dayOfWeek}
              hasDeliveryAreaDeliveryFeePermission={
                hasDeliveryAreaDeliveryFeePermission
              }
            />
            <DeliveryAreaSlotsStartTime
              id={slot.id}
              control={control}
              isDisabled={globalIsDisabled}
              startTime={slot.slotFrom}
              hasDeliveryAreaDeliveryFeePermission={
                hasDeliveryAreaDeliveryFeePermission
              }
            />
            <DeliveryAreaSlotsEndTime
              id={slot.id}
              control={control}
              isDisabled={globalIsDisabled}
              endTime={slot.slotTo}
              hasDeliveryAreaDeliveryFeePermission={
                hasDeliveryAreaDeliveryFeePermission
              }
            />
            <DeliveryAreaSlotsQuotas
              id={slot.id}
              control={control}
              isDisabled={globalIsDisabled}
              quotas={slot.maxConcurrent}
              hasDeliveryAreaDeliveryFeePermission={
                hasDeliveryAreaDeliveryFeePermission
              }
            />
            <DeliveryAreaSlotsStatus
              id={slot.id}
              control={control}
              isDisabled={globalIsDisabled}
              status={slot.enabled}
              hasDeliveryAreaDeliveryFeePermission={
                hasDeliveryAreaDeliveryFeePermission
              }
            />
            <hr />

            <StyledRemoveSlotContainer>
              <StyledRemoveSlotButton
                variant="contained"
                color="error"
                disabled={removingSlotId === slot.id && loadingDeleteSlotsByIds}
                onClick={() => handleDeleteSlot(slot.id)}
              >
                {removingSlotId === slot.id ? "Removing..." : "Remove slot"}
              </StyledRemoveSlotButton>
            </StyledRemoveSlotContainer>
          </div>
        );
      })}

      <StyledAddSlotContainer>
        <StyledAddSlotButton
          variant="contained"
          color="success"
          onClick={handleAddNewSlot}
          disabled={globalIsDisabled}
        >
          Add New Slot
        </StyledAddSlotButton>
      </StyledAddSlotContainer>
    </StyledWrapper>
    )
  )
};

export default DeliveryAreaSlots;
